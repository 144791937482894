var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy
  return _c(
    "div",
    { key: `recipients_key_${_vm.key}`, staticStyle: { position: "relative" } },
    [
      _c("div", { staticClass: "kt-input-icon" }, [
        _c("input", {
          directives: [
            {
              name: "model",
              rawName: "v-model",
              value: _vm.searchTerm,
              expression: "searchTerm",
            },
          ],
          staticClass: "form-control",
          class: _vm.showStyling ? "pl-5" : "ml-0 pl-1",
          staticStyle: { background: "transparent !important" },
          style: !_vm.showStyling
            ? "border: none !important; outling: none !important"
            : "",
          attrs: {
            type: "text",
            placeholder:
              _vm.placeholder === null
                ? "Course, Staff, Guardian, or Student"
                : _vm.placeholder,
          },
          domProps: { value: _vm.searchTerm },
          on: {
            blur: function ($event) {
              return _vm.blurEventHandler($event)
            },
            focus: function ($event) {
              return _vm.focusEventHandler($event)
            },
            keyup: function ($event) {
              if (
                !$event.type.indexOf("key") &&
                _vm._k($event.keyCode, "enter", 13, $event.key, "Enter")
              )
                return null
              return _vm.selectPortfolio(0)
            },
            keydown: function ($event) {
              _vm.showPicker = true
            },
            input: function ($event) {
              if ($event.target.composing) return
              _vm.searchTerm = $event.target.value
            },
          },
        }),
        _vm.showStyling
          ? _c(
              "span",
              { staticClass: "kt-input-icon__icon kt-input-icon__icon--left" },
              [_vm._m(0)]
            )
          : _vm._e(),
      ]),
      _vm.recipients.length > 0 && _vm.showPicker
        ? _c(
            "div",
            { staticClass: "kt-widget4 p-3 mt-2 w-100" },
            _vm._l(_vm.filteredRecipients, function (user, idx) {
              return _c(
                "div",
                {
                  key: `${idx}_portfolio`,
                  staticClass: "kt-widget4__item",
                  staticStyle: { cursor: "pointer" },
                  on: {
                    click: function ($event) {
                      $event.stopPropagation()
                      $event.preventDefault()
                      return _vm.selectPortfolio(idx)
                    },
                  },
                },
                [
                  _c(
                    "div",
                    { staticClass: "kt-widget4__pic kt-widget4__pic--pic" },
                    [
                      _c("UserAvatar", { attrs: { "avatar-user": user } }, [
                        _c(
                          "div",
                          {
                            staticClass:
                              "kt-badge kt-badge--lg kt-badge--success",
                          },
                          [
                            _vm._v(
                              " " + _vm._s(user.name.substring(0, 1)) + " "
                            ),
                          ]
                        ),
                      ]),
                    ],
                    1
                  ),
                  _c("div", { staticClass: "kt-widget4__info" }, [
                    _c(
                      "a",
                      {
                        staticClass: "kt-widget4__username",
                        attrs: { href: "#" },
                        on: {
                          click: function ($event) {
                            $event.stopPropagation()
                            $event.preventDefault()
                            return _vm.selectPortfolio(idx)
                          },
                        },
                      },
                      [_vm._v(" " + _vm._s(user.name) + " ")]
                    ),
                    _c("p", { staticClass: "kt-widget4__text" }, [
                      _vm._v(" " + _vm._s(user.subName) + " "),
                    ]),
                  ]),
                ]
              )
            }),
            0
          )
        : _vm._e(),
    ]
  )
}
var staticRenderFns = [
  function () {
    var _vm = this,
      _c = _vm._self._c,
      _setup = _vm._self._setupProxy
    return _c("span", [_c("i", { staticClass: "la la-search" })])
  },
]
render._withStripped = true

export { render, staticRenderFns }