import { decryptObject } from '../../../../shared/encryption.mjs';

const studentGuardianMixins = {
    methods: {
        $_studentGuardianMixins_getStudentGuardians(limitBy) {
            const { database } = this.$store.state;
            return getGuardians(database, limitBy);
        },
    },
};

function StudentGuardianFilter(props) {
    this.studentEnrollmentId = props.studentEnrollmentId || false;
    return this;
}

function getGuardians(database, limitBy = {
    studentEnrollmentId: null,
}) {

    const { studentGuardians, schoolTermEncryptionKey } = database;
    const {
        studentEnrollmentId,
    } = limitBy;

    return studentGuardians
        .filter((s) => {
            if (studentEnrollmentId) {
                return s.studentEnrollmentId == studentEnrollmentId;
            }
            return true;
        }).map((s) => {
            let guardian = { ...s };
            return decryptGuardian(guardian, schoolTermEncryptionKey);
        });
}


function decryptGuardian(guardian, schoolTermEncryptionKey) {
    if (schoolTermEncryptionKey) {
        const { isEncrypted, encryptedData } = guardian;
        if (isEncrypted) {
            guardian = decryptObject(guardian, encryptedData, schoolTermEncryptionKey);
            guardian.isEncrypted = false;
        }
        guardian.phones = guardian.phones.map((p) => {
            if (p.phoneIsEncrypted) {
                const decryptedPhone = decryptObject(p, p.phoneEncryptedData, schoolTermEncryptionKey);
                return decryptedPhone;
            }
            return p;
        });
        return guardian;
    }
    return guardian;
}
export {
    studentGuardianMixins as default,
    StudentGuardianFilter,
    decryptGuardian,
};
